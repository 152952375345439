<template>
  <div class="form-payment bg-white">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col cols="3">
          <div
            style="cursor: pointer;"
            @click="prevPage"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-5 fw-bold-700 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col cols="6">
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            Transfer Bank
          </h1>
        </b-col>
        <b-col cols="3" />
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <div class="text-center mt-3">
        <h5 class="text-dark text-darken-4 fw-bold-800 mb-1 size14">
          Pembayaran yang diinputkan {{ parseInt(price) | formatAmount }}
        </h5>
      </div>
    </b-container>
    <TransferBank
      :submitTransferBank="submit"
      :submit="submit"
      @payload="getPayload"
      @payloadBank="getPayloadBank"
    />
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol, VBToggle, BFormGroup, BFormInput, BForm, BFormSelect, BButton, BProgress,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification, preformatFloat } from '@/auth/utils'
import TransferBank from '@/components/Payment/TransferBank/index.vue'
import FormLengkapiAlamat from '@/components/Payment/FormLengkapiAlamat.vue'

export default {
  title() {
    return 'Pembayaran - Transfer Bank'
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BButton,
    BProgress,
    FormLengkapiAlamat,
    TransferBank,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification, preformatFloat
    }
  },
  data() {
    return {
      comp: 'FormStep1',
      step: this.$store.state.formWizard.step,
      progressValue: 50,
      test: 0,
      price: localStorage.getItem('price') || localStorage.getItem('cash_total_payment'),
      banks: {},
      formPayload: {
        payments: []
      },
      addtionalCost: []
    }
  },
  watch: {
    '$store.state.cart.totalTransaksi': function (value) {
      this.total = value
    },
  },
  created() {
    this.formPayload = JSON.parse(localStorage.getItem('payloadTransaksi'))
  },
  methods: {
    getPayload(value) {
      this.formPayload = value
    },
    getPayloadBank(value) {
      this.banks = value
    },
    async submit() {
      let payloadDelivery = null
      if(localStorage.payloadDelivery) {
        payloadDelivery = JSON.parse(localStorage.payloadDelivery)
        this.formPayload.receiver_name = payloadDelivery.receiver_name
        this.formPayload.receiver_phone_number = payloadDelivery.receiver_phone_number
        this.formPayload.receiver_address = payloadDelivery.receiver_address
      }
      const payloadTransaksi = JSON.parse(localStorage.payloadTransaksi)
      if (payloadTransaksi.delivery_pickup == 'pickup') {
        delete this.formPayload.receiver_name
        delete this.formPayload.receiver_phone_number
        delete this.formPayload.receiver_address
      }
      let poSO = JSON.parse(localStorage.poSO)
      this.formPayload.po_so_number = poSO.po_so_number
      this.formPayload.additional_fees = JSON.parse(localStorage.getItem('additional_cost'))
      this.formPayload.customer_uuid = localStorage.getItem('customer_uuid')
      
      this.$store.commit('checkout/setIsLoading', true)
      this.formPayload.payments = [
        {
          payment_method: localStorage.getItem('payment_method'),
          amount: parseFloat(preformatFloat(this.$route.query.amount_dp)) || localStorage.getItem('cash_total_payment'),
          bank_name: this.banks.bank_name,
          bank_account_name: this.banks.bank_account_name,
          bank_account_number: this.banks.bank_account_number,
          bank_account_payment_uuid: this.banks.bank_account_payment_uuid,
          bank_transfer_attachment: this.banks.bank_transfer_attachment,
        }
      ]

      const form = this.preparePayload()

      await this.$store.dispatch('checkout/submitCheckout', {
        uuid: localStorage.getItem('cartUuid'),
        payload: form,
      }).then(result => {
        this.$store.commit('formWizard/setStep', 1)
        const item = result.data.data
        this.$router.push({ name: 'payment.success', query: { uuid: item.uuid, cash_change: item.cash_change, total: item.total, amount: this.$route.query.amount_dp, type_payment: this.$route.query.type_payment } })
        localStorage.removeItem('cartUuid')
        localStorage.removeItem('customer_type')
        localStorage.removeItem('customer_uuid')
        localStorage.removeItem('cash_total_payment')
        localStorage.removeItem('payment_method')
        localStorage.removeItem('price')
        localStorage.removeItem('poSO')
        this.$store.commit('checkout/setIsLoading', false)
      }).catch(err => {
        this.$store.commit('checkout/setIsLoading', false)
        // eslint-disable-next-line no-restricted-syntax
        for (const key in err.response.data.meta.messages) {
          if (Object.hasOwnProperty.call(err.response.data.meta.messages, key)) {
            const element = err.response.data.meta.messages[key]
            errorNotification(this, 'Oops!', element.join(' '))
          }
        }
      })
    },
    preparePayload() {
      delete this.formPayload.cash_total_payment
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'additional_fees' && key !== 'payments') {
            form.append(key, this.formPayload[key])
          }
          if (key === 'payments') {
            for (let index = 0; index < this.formPayload.payments.length; index++) {
              const element = this.formPayload.payments[index];
              element.payment_type = this.$route.query.type_payment == 1 ? 'full_payment' : 'down_payment'
              element.payment_customer_uuid = localStorage.getItem('customer_uuid')
              for (const keyPayment in element) {
                if (Object.hasOwnProperty.call(element, keyPayment) && element) {
                  const item = element[keyPayment];
                  if (item) {
                    form.append(`payments[${index}][${keyPayment == 'type' ? 'payment_method' : keyPayment}]`, item)
                  }
                }
              }
            }
          }
          if (key === 'additional_fees') {
            for (const keySecond in this.formPayload[key]) {
              // eslint-disable-next-line no-prototype-builtins
              if (this.formPayload[key].hasOwnProperty(keySecond) && this.formPayload[key][keySecond] != null) {
                for (const keyThird in this.formPayload[key][keySecond]) {
                  if (Object.hasOwnProperty.call(this.formPayload[key][keySecond], keyThird)) {
                    const element = this.formPayload[key][keySecond][keyThird];
                    // console.log(`additional_fees[${keySecond}][${keyThird}]`, keyThird == 'additional_fee_note' ? element : parseFloat(preformatFloat(element)))
                    form.append(`additional_fees[${keySecond}][${keyThird}]`, keyThird == 'additional_fee_note' ? element : parseFloat(preformatFloat(element)))
                  }
                }
              }
            }
          }
        }
      }
      return form
    },
    prevPage() {
      switch (this.step) {
        case 1:
          this.$router.go(-1)
          break
        case 2:
          this.$store.commit('formWizard/setStep', 1)
          break
        default:
          break
      }
    },
    stepForm() {
      switch (this.step) {
        case 1:
          this.comp = 'FormStep1'
          this.progressValue = 50
          break
        case 2:
          this.comp = 'FormLengkapiAlamat'
          this.progressValue = 100
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>

body {
  background: #FFFFFF;
}

.form-payment {
  height: 100vh;

  .custom__form--input mb-0 {
    label {
      margin-bottom: 1rem !important;
    }
  }

  .image-box {
    width: 46px;
    height: 46px;
  }
  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
</style>
